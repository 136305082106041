/* Estilos padrão para o rodapé */
.rodape {
    background-color: #000;
    padding: 2rem;
    text-align: center;
  }
  
  .rodape h2 {
    font-weight: 400;
    font-size: 18px;
    color: #fff;
  }
  
  /* Estilos para dispositivos móveis (max-width: 767px) */
  @media (max-width: 767px) {
    .rodape {
      width: 100%;
      padding: 1rem 0; /* Adicionar espaçamento superior para empurrar o rodapé para baixo */
    }
  
    .rodape h2 {
      font-size: 12px;
    }
  }
  