.admin-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
}

.admin-container h1{
    margin-bottom: 28px;

}

.btn-register{
    margin-bottom: 28px;
}

.list{
    width: 90%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    background-color: rgba(18,18,18, 0.38);
    border-radius: 8px;
    margin-bottom: 12px;
    justify-content: center;
    padding: 14px 8px;
  
}

.list:hover{
   opacity: 0.9;
}

.list p{
    margin-bottom: 8px;
}

.list button{
    margin-right: 8px;
    border: 0;
    border-radius: 4px;
    padding: 4px;
}

.btn-delete{
    color: #fff;
    background-color: #cc0000;
    cursor:pointer;
}

.btn-logout{
    position: absolute;
    bottom: 6%;
    left: 4%;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    border: 0;
    font-weight: bold;
    background-color: rgba(219, 38, 41, 0.35);
    color: #fafafa;
    transition: all 0.5s;
}

.btn-logout:hover{
    background-color: rgba(219, 38, 41, 1);
    color: #fff;
}

.imagemAdmin{
    width:20%;
    align-self: center;
}

.titulo-postados{
    color: #18122B;
    margin-bottom: 5px;
}

.secaoBotoes{
    display: flex;
    justify-content: space-between;
}

.tituloVideo{
    font-size: 1rem;
}