.containerButton {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 5px 24px;
  position: relative;
  background-color: #000000;
  z-index: 0;
}

.containerButton:hover {
  background: #2e3135;
  color: #174ea6;
}

.containerButton:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.containerButton:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.containerButton:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.containerButton:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.containerButton:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.containerButton:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.containerButton:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

@media (max-width: 768px) {
  .containerButton {
    padding: 4px 15px;
    border-radius: 4px;
    height: 30px;
  }
}