.containerCard {
    width: 360px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.5), 
    0px 2px 4px rgba(236, 234, 234, 0.1),
    0px 4px 8px rgba(0,0,0,0.1),
    0px 8px 16px rgba(0,0,0,0.1);

}

.textoCard {
    font-size: 1rem;
    color: #f0faf7;
    text-decoration: none; /* Remover sublinhado para navegadores modernos */
    padding-top: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: 30px;
}


.cardImg {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    transition: 0.5s;
}

.cardImg:hover{
    opacity: 0.8;
}


@media (max-width: 767px){
    .containerCard {
        width: 355px;
        height: 220px;
    }
    .textoCard{
        word-wrap: break-word;
      
        text-align: center;
    }

}