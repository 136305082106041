.containerVideo{
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
}
.containerVideo h1{
 color: #f0faf7;
}


  .videoWrapper iframe {
    width: 700px;
    height: 393px;
    max-width: 100%; /* Garantir que o vídeo não exceda a largura máxima do contêiner */

    top: 0;
    left: 0;
  }

  .backButtonVideoLink{
    right: 580px;
    margin-top: 15px;
  }

 
  /* Estilo para celular */
  @media (max-width: 767px) {
 
    .videoWrapper iframe {
      width: 350px;
      height: 196px; /* Ajuste a altura para manter a proporção 4:3 */
    }

    .containerVideo h1{
        font-size: 1rem;
        margin-top: 70px;
        text-align: center;
        word-wrap: break-word;
    }

    .containerVideo{
        height: 85vh;
        justify-content: start;
    }

    .videoWrapper{
        margin-top: 5em;
     
    }

    .backButtonVideoLink{
      right: 140px;
      margin-top: 15px;
    }
  }