.cabecalho {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.1em 20em;
    background-color:#1C1C1C;
}

.imgLogo{
    width: 30%;
    max-width: 100%;
}

@media (max-width: 767px) {
    .cabecalho {
      padding: 0.5em 1em; /* Ajuste o padding para se adaptar a tamanhos menores */
    }
  
    .imgLogo {
      width: 50%; /* Para dispositivos menores, deixe a imagem ocupar a largura total */
    }
  }